<template lang="pug">
include ../../../../configs/template
form.row.mt-8.w-100
  div.col-12
    +select-validation('body.typeExperience','listTypesDocument','typeDoc','nameLang','["required"]')( :readonly="[TYPE_DOCUMENTS_CONSTANTS.SERVICE_RECORD, TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_IN_SERVICE_RECORD].includes(typeDocument) || isCheckDocument")
  SailorRecordBook(v-if="body.typeExperience === TYPE_DOCUMENTS_CONSTANTS?.SERVICE_RECORD" ref="SailorRecordBook" :isCheckDocument="isCheckDocument").w-100
  SailorRecordBookLineAndExperienceCertificate(
    v-if="[TYPE_DOCUMENTS_CONSTANTS?.EXPERIENCE_IN_SERVICE_RECORD, TYPE_DOCUMENTS_CONSTANTS?.EXPERIENCE_CERTIFICATE].includes(body.typeExperience)"
    ref="SailorRecordBookLineAndExperienceCertificate"
    :sailorId="sailorId"
    :serviceRecordBookFiles="files"
    :typeExperienceId="body.typeExperience"
    :isCheckDocument="isCheckDocument").w-100
  EmploymentHistory(v-if="body.typeExperience === TYPE_DOCUMENTS_CONSTANTS?.EXPERIENCE_HISTORY" ref="EmploymentHistory" :isCheckDocument="isCheckDocument").w-100
  div(v-if="isCheckDocument").col-12
    FileDropZone(ref="mediaContent")
  div(v-if="isCheckDocument").col-12
    v-btn(color="success" @click="validateData" :loading="isLoadingSave").mr-4 {{$t('save')}}
    v-btn(color="secondary" @click="clearForm") {{ $t('btn.clear') }}
  div(v-if="!isCheckDocument && body.typeExperience").col-12
    v-btn(color="success" @click="checkDocument" :loading="isLoading") {{$t('btn.checkDocument')}}
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { verificationISC } from '@/mixins/validationRules'
import { TYPE_DOCUMENTS_CONSTANTS } from '@/configs/constants'
import { clearBody } from '@/mixins/main'

export default {
  props: {
    sailorId: { type: Number, default: null },
    files: { type: Array, default: () => ([]) },
    typeDocument: { type: Number, default: null }

  },
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue'),
    EmploymentHistory: () => import('./EmploymentHistory.vue'),
    SailorRecordBook: () => import('./SailorRecordBook.vue'),
    SailorRecordBookLineAndExperienceCertificate: () => import('./SailorRecordBookLineAndExperienceCertificate.vue')
  },
  data () {
    return {
      TYPE_DOCUMENTS_CONSTANTS,
      body: {
        typeExperience: this.typeDocument || null
      },
      isCheckDocument: false,
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      documentTypes: state => state.directory.verificationDocumentTypes,
      listConventionalTypeDoc: state => state.directory.listConventionalTypeDoc,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      isLoadingSave: state => state.documents.isLoading
    }),
    listTypesDocument () {
      return this.documentTypes.concat(this.listConventionalTypeDoc).filter(el => (
        [
          TYPE_DOCUMENTS_CONSTANTS.SERVICE_RECORD,
          TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_IN_SERVICE_RECORD,
          TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_CERTIFICATE,
          TYPE_DOCUMENTS_CONSTANTS?.EXPERIENCE_HISTORY].includes(el.id)
      )).filter(el => this.typeDocument === TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_CERTIFICATE
        ? ![TYPE_DOCUMENTS_CONSTANTS.SERVICE_RECORD, TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_IN_SERVICE_RECORD].includes(el.id) : el)
    }
  },
  validations () { return verificationISC.experience() },
  watch: {
    'body.typeExperience' (newVal, oldVal) {
      if (newVal && newVal?.id !== oldVal?.id) {
        this.clearForm()
      }
    }
  },
  methods: {
    ...mapActions(['getInfoAboutDocumentForDigitizationSC']),
    validateData () {
      const { statementId } = this.$route.params
      let verificationData = {
        id: statementId,
        data: {}
      }
      if (this.$v.$invalid) return this.$v.$touch()
      switch (this.body.typeExperience) {
        case TYPE_DOCUMENTS_CONSTANTS.SERVICE_RECORD:
          if (this.$refs.SailorRecordBook.$v.$invalid) return this.$refs.SailorRecordBook.$v.$touch()
          verificationData.data.body = { ...this.$refs.SailorRecordBook.body }
          verificationData.data.body.additional_info.auth_agent_eng = `${this.$refs.SailorRecordBook.last_name_eng} ${this.$refs.SailorRecordBook.first_name_eng}`
          verificationData.data.body.additional_info.auth_agent_ukr = `${this.$refs.SailorRecordBook.last_name_ukr} ${this.$refs.SailorRecordBook.first_name_ukr}`
          verificationData.data.media = {
            name: 'RecordBookDoc',
            files: [...this.$refs.mediaContent.filesArray]
          }
          verificationData.data.body.type_override = this.body.typeExperience
          break
        case TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_IN_SERVICE_RECORD:
          if (this.$refs.SailorRecordBookLineAndExperienceCertificate.$v.$invalid) {
            return this.$refs.SailorRecordBookLineAndExperienceCertificate.$v.$touch()
          }
          this.$refs.SailorRecordBookLineAndExperienceCertificate.body.additional_info.level_refriger_plant = +this.$refs.SailorRecordBookLineAndExperienceCertificate.body.additional_info.level_refriger_plant
          verificationData.data.body = { ...this.$refs.SailorRecordBookLineAndExperienceCertificate.body }
          verificationData.data.media = {
            name: 'ExperienceDoc',
            files: [...this.$refs.mediaContent.filesArray]
          }
          verificationData.data.body.selected_photos = [this.$refs.SailorRecordBookLineAndExperienceCertificate.selected_photos]
          verificationData.data.body.type_override = this.body.typeExperience
          break
        case TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_CERTIFICATE:
          if (this.$refs.SailorRecordBookLineAndExperienceCertificate.$v.$invalid) {
            return this.$refs.SailorRecordBookLineAndExperienceCertificate.$v.$touch()
          }
          this.$refs.SailorRecordBookLineAndExperienceCertificate.body.additional_info.level_refriger_plant = +this.$refs.SailorRecordBookLineAndExperienceCertificate.body.additional_info.level_refriger_plant
          verificationData.data.body = clearBody({ ...this.$refs.SailorRecordBookLineAndExperienceCertificate.body })
          verificationData.data.media = {
            name: 'ExperienceDoc',
            files: [...this.$refs.mediaContent.filesArray]
          }
          verificationData.data.body.type_override = this.body.typeExperience
          verificationData.data.body.additional_info.record_type = 'Довідка про стаж плавання'
          break
        case TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_HISTORY:
          if (this.$refs.EmploymentHistory.$v.$invalid) {
            return this.$refs.EmploymentHistory.$v.$touch()
          }
          verificationData.data.body = { ...this.$refs.EmploymentHistory.body }
          verificationData.data.body.additional_info.record_type = this.listTypesDocument.find(el => this.body.typeExperience === el.id).name_ukr
          verificationData.data.body.type_override = TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_CERTIFICATE
          verificationData.data.media = {
            name: 'ExperienceDoc',
            files: [...this.$refs.mediaContent.filesArray]
          }
          break
      }
      verificationData.method = 'setDigitizationDocumentSC'
      this.$emit('saveDocument', verificationData)
    },
    async checkDocument () {
      const { statementId } = this.$route.params
      let data = {
        id: statementId,
        body: {}
      }
      switch (this.body.typeExperience) {
        case TYPE_DOCUMENTS_CONSTANTS.SERVICE_RECORD:
          if (this.$refs?.SailorRecordBook?.$v.$invalid) return this.$refs.SailorRecordBook.$v.$touch()
          data.body.additional_info = { ...clearBody({ ...this.$refs.SailorRecordBook.body.additional_info }) }
          data.body.type_override = this.body.typeExperience
          break
        case TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_IN_SERVICE_RECORD:
          if (this.$refs?.SailorRecordBookLineAndExperienceCertificate.$v.$invalid) return this.$refs.SailorRecordBookLineAndExperienceCertificate.$v.$touch()
          data.body.additional_info = { ...clearBody({ ...this.$refs.SailorRecordBookLineAndExperienceCertificate.body.additional_info }) }
          data.body.type_override = this.body.typeExperience
          break
        case TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_CERTIFICATE:
          if (this.$refs?.SailorRecordBookLineAndExperienceCertificate.$v.$invalid) return this.$refs.SailorRecordBookLineAndExperienceCertificate.$v.$touch()
          data.body.additional_info = { ...clearBody({ ...this.$refs.SailorRecordBookLineAndExperienceCertificate.body.additional_info }) }
          data.body.type_override = this.body.typeExperience
          break
        case TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_HISTORY:
          if (this.$refs.EmploymentHistory.$v.$invalid) return this.$refs.EmploymentHistory.$v.$touch()
          data.body.additional_info = { ...clearBody({ ...this.$refs.EmploymentHistory.body.additional_info }) }
          data.body.type_override = TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_CERTIFICATE
          break
      }
      this.isLoading = true
      const response = await this.getInfoAboutDocumentForDigitizationSC(data)
      if (response) {
        this.$notification.success('notify.success.checkDocument')
        this.isCheckDocument = true
      }
      this.isLoading = false
    },
    clearForm () {
      this.isCheckDocument = false
      if (this.$refs.SailorRecordBook) {
        this.$refs.SailorRecordBook.body = this.$refs?.SailorRecordBook?.initBody()
        this.$refs.SailorRecordBook.last_name_eng = null
        this.$refs.SailorRecordBook.first_name_eng = null
      } else if (this.$refs.SailorRecordBookLineAndExperienceCertificate) this.$refs.SailorRecordBookLineAndExperienceCertificate.body = this.$refs?.SailorRecordBookLineAndExperienceCertificate?.initBody()
      else if (this.$refs.EmploymentHistory) this.$refs.EmploymentHistory.body = this.$refs?.EmploymentHistory?.initBody()
      this.$v.$reset()
    }
  }
}
</script>
